import React from "react";
import arrow from "../../assets/media/arrow_left.svg"
import Chart from "assets/media/chart";

const ChatBlock = ({ setChat, setQtn, chatCon, handleChange, handleSubmit, qtn, setChatCon }) => {
    console.log(qtn, "chatCon");
    return (
        <div className="xs:mx-4 sm:container sm:px-40 sm:mx-auto xs:h-auto sm:h-auto pt-10">
            <div className="flex gap-2 items-center pb-3" onClick={() => { setQtn(""); setChat(false); setChatCon([]) }}>
                <div className="flex gap-2 items-center cursor-pointer">
                    <div>
                        <img src={arrow} className="w-2.5" alt='arrow' />
                    </div>
                    <div>
                        <p className="text-[#3D4FE0]">Back</p>
                    </div>
                </div>
                <div className="flex-1 text-center font-bold text-[25px]">
                    <p>A.I. Home Service First Responder</p>
                </div>
            </div>
            <div className="bg-[#dcdcdc] h-[600px] rounded-lg p-5 flex flex-col overflow-auto gap-3">
                {chatCon.length > 0 ?
                    chatCon?.map((val, index) => (
                        <div className={`flex-[0_0_10%] inline-flex ${val?.key === 'me' ? 'justify-end' : 'justify-start'}`}>
                            <div className={`w-[47%] ${val?.key === 'me' ? 'bg-white text-black' : 'bg-[#3D4FE0] text-white'} p-3 rounded-md`}>
                                {val.key === 'me' ? val?.qtn : val.ans}
                            </div>
                        </div>
                    ))
                    :
                    <div className="flex h-full justify-center items-center flex-col gap-5">
                        <div className="bg-white rounded-full flex justify-center items-center w-[100px] h-[100px]">
                            <Chart />
                        </div>
                        <p className="bg-white px-4 py-2 rounded-full text-xl font-medium">Start Conversation</p>
                    </div>
                }
            </div>
            <div className="relative xs:pb-2 sm:py-3">
                <input
                    onChange={(e) => {
                        handleChange(e.target.value);
                    }}
                    value={qtn}
                    className="w-full border-[1px] px-6 py-3 rounded-lg outline-none"
                    type="text"
                    placeholder="What's going on?"
                    onKeyDown={(e) => {
                        if (e.code === "Enter")
                            handleSubmit()
                    }}
                />
                <div
                    className="absolute right-4 top-[22px] cursor-pointer"
                    onClick={handleSubmit}
                >
                    <button className="px-4 py-1 bg-[#3D4FE0] rounded-md text-white shadow-[1px_1px_10px_#dcdcdc]">
                        Send
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChatBlock;