import React, { useState } from "react";
import "../assets/css/style.css";
import Header from "component/header";
import Feature from "component/feature/featureBody";
import WebFrame from "component/iframe";
import SearchBar from "component/searchBar";
import ChatBlock from "component/chart";
import Footer from "component/Footer";
import backImage from "../assets/media/bgImage.png";
import BlogContainer from "component/blog";

const Home = () => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(false);
  const [chat, setChat] = useState(false);
  const [chatCon, setChatCon] = useState([]);
  const handleFrame = (url) => {
    url && setUrl(url);
    setOpen((open) => !open);
  };
  const [qtn, setQtn] = useState("");

  const handleChange = (value) => {
    if (value.length === 2) {
      setTimeout(() => {
        setChat(true);
      }, 1000);
    }
    setQtn(value);
    console.log(value, "value vaue");
  };
  const handleSubmit = async () => {
    console.log(qtn, "qtnqtnqtn");
    const meData = {
      key: 'me',
      qtn
    }
    setChatCon((preState) => [...preState, meData]);
    const data = {
      question: qtn,
      api_key: "",
      embeddings_key: "",
      history: [{ prompt: qtn }],
      active_docs: "local/default/",
      conversation_id: null,
      prompt_id: "default",
    };
    setQtn("");
    await fetch("https://api-bldg-gpt.walkthru.ai/api/answer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => setChatCon((preState) => [...preState, { key: 'answer', ans: data?.answer }]))
      .catch((error) => console.error(error));
  };
  return (
    <div className="xs:h-full sm:h-[100vh] bg-white flex flex-col gap-0">
      {!chat && <>
        <div className="xs:h-full sm:h-[100vh] w-full flex flex-col pt-0" style={{
          background: `url(${backImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          backdropFilter: 'blur(5px)'
        }}>
          <Header />
          <div className="flex justify-between flex-col">
            <Feature handleFrame={handleFrame} setChat={setChat} />
            <SearchBar
              setChat={setChat}
            />
          </div>
          {open && <WebFrame url={url} handleFrame={handleFrame} />}
        </div>
        {/* <div>
          <BlogContainer />
        </div> */}
      </>}
      {chat && (
        <ChatBlock
          setChat={setChat}
          setQtn={setQtn}
          handleChange={handleChange}
          qtn={qtn}
          setChatCon={setChatCon}
          handleSubmit={handleSubmit}
          chatCon={chatCon} />
      )}
      <Footer />
    </div>
  );
};

export default Home;
