import React from "react";
import AppStore from "../../assets/media/app-store.png";
import PlayStore from "../../assets/media/play-store.png";

const index = () => {
  return (
    <div className="bg-[#f5f7fa] h-auto">
      <div className="container max-w-[1200px] flex xs:flex-col sm:flex-row m-auto xs:gap-6 justify-between">
        <div className="flex items-center py-10 px-8 flex-col lg:flex-row sm:justify-center gap-2">
          <p className="text-xl text-slate-500 font-extrabold max-w-[300px] text-center">
            Download Contractor United App to Experience from anywhere
          </p>
          <div className="flex w-full justify-center">
            <img
              src={PlayStore}
              alt="play-store"
              width={152}
              height={100}
              style={{ cursor: "pointer", zIndex: 2 }}
              onClick={(event) => {
                event.stopPropagation();
                window.open(
                  "https://play.google.com/store/apps/details?id=app.bldg.explainer",
                  "_blank"
                );
              }}
            />
            <img
              src={AppStore}
              alt="app-store"
              width={152}
              height={100}
              style={{ cursor: "pointer", zIndex: 2 }}
              onClick={(event) => {
                event.stopPropagation();
                window.open(
                  "https://apps.apple.com/us/app/walkthru-ai/id6450741225",
                  "_blank"
                );
              }}
            />
          </div>
        </div>
        <div className="flex p-8 gap-20 xs:justify-center">
          <div className="flex gap-2 flex-col">
            <p className="font-bold mb-2">Resources</p>
            <p className="mb-2 text-slate-500 cursor-pointer">Blog</p>
            <p className="mb-2 text-slate-500 cursor-pointer">FAQ</p>
            <p className="mb-2 text-slate-500 cursor-pointer">Help Center</p>
          </div>
          <div className="flex gap-2 flex-col">
            <p className="font-bold mb-2">Community</p>
            <p className="mb-2 text-slate-500 cursor-pointer">Contact us</p>
            <p className="mb-2 text-slate-500 cursor-pointer">About us</p>
            <p className="mb-2 text-slate-500 cursor-pointer">Newsletters</p>
          </div>
          <div className="flex gap-2 flex-col">
            <p className="font-bold mb-2">Terms</p>
            <p className="mb-2 text-slate-500 cursor-pointer">Privacy policy</p>
            <p className="mb-2 text-slate-500 cursor-pointer">Cookie policy</p>
            <p className="mb-2 text-slate-500 cursor-pointer">Terms of use</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
