import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import arrow from "../../assets/media/arrow.svg";

const cardVariants = {
  offscreen: {
    y: 200,
  },
  onscreen: {
    y: 10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const SearchBar = ({ setChat }) => {
  useEffect(() => {
    const handleFocus = (e) => {
      if (e.code === "Enter") {
        // handleSubmit()
      }
    };

    document.addEventListener("keydown", handleFocus);

    return () => {
      document.removeEventListener("keydown", handleFocus);
    };
  }, []);
  const inputRef = useRef(null);
  return (
    <motion.div
      className="card-container bg-[#3D4FE0]"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.2 }}
    >
      <motion.div className="card" variants={cardVariants}>
        <div className="container mx-auto w-full h-auto flex gap-4 flex-col justify-center items-center pb-10">
          <div>
            <p className="text-white xs:text-center sm:text-left font-[600] xs:text-[20px] sm:text-[35px] pt-1">Not able to find what you are looking for?</p>
          </div>
          <div className="w-[150px] mx-auto cursor-pointer text-center py-2 rounded-full text-[20px] font-medium text-white bg-[#ED0F5A] flex items-center justify-center gap-2"
            onClick={() => { setChat(true) }}
          >
            <p>Start</p>
            <img src={arrow} className="w-8" alt="arrow" />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default SearchBar;
