import Spinner from "component/spinner";
import React, { useEffect, useState } from "react";

const WebFrame = ({ url, handleFrame }) => {
  console.log(url, "url");
  const [load, setLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 2000);
  }, []);
  return (
    <div className="absolute top-0 left-0 z-10 bg-[#000000fa] w-full h-[100%] flex flex-col justify-center items-center">
        <div className=" absolute xl:top-[8%] xl:right-[34%] top-[10%] right-[35%]">
          <p
            className="text-white hover:outline hover:outline-offset-2 font-bold outline-pink-600 cursor-pointer bg-pink-700 rounded-full w-6 h-6 flex justify-center items-center"
            onClick={() => {
              handleFrame();
            }}
          >
            X
          </p>
        </div>
        {load && (
          <div className="animate-pulse w-[400px] h-[700px] bg-slate-200 rounded-lg absolute z-50"></div>
        )}
        
          <iframe
            width="400"
            height="700"
            src={url}
            title="i"
            style={{ borderRadius: 10,position:"relative" }}
          ></iframe>
      </div>
  );
};

export default WebFrame;
