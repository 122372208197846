import React from "react";
import { motion } from "framer-motion";
import arrow from "../../assets/media/arrow.svg";
import SearchBar from "component/searchBar";

const cardVariants = {
  offscreen: {
    y: 200,
  },
  onscreen: {
    y: 10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const Feature = ({ handleFrame, setChat }) => {
  const imgData = [
    {
      img: "https://assets.bldghealth.net/videos/processed/656f49d40b3783a2a73000c8/thumbnail/1701792214031_Troubleshooting Intro_thumbnail.0000001.jpg",
      url: "https://qa-play.walkthru.ai/walkthru/6572ef69758354673169f776",
      title: "HVAC Cooling Issue",
    },
    {
      img: "https://assets.bldghealth.net/videos/processed/656f49d40b3783a2a73000c8/thumbnail/1701792214031_Troubleshooting Intro_thumbnail.0000001.jpg",
      url: "https://qa-play.walkthru.ai/walkthru/65732775fd00d1cff6704ec5",
      title: "HVAC Heating Issue",
    },
  ];
  return (
    <div className="xs:h-auto sm:h-auto flex-1 container mx-auto">
      <div className="">
        <div className="text-center text-white font-bold">
          <h1 className="xs:text-[30px] sm:text-[60px]">Home Services First Responder</h1>
          <h4 className="xs:text-[18p] sm:text-[30px]">Most used self-help lines</h4>
        </div>
        <div className="xs:pt-10 sm:pt-0">
          <h2 className="xs:text-[20px] sm:text-[30px] text-white font-medium">Featured Walkthru</h2>
        </div>
        <div className={`flex flex-wrap items-center gap-8 pt-6 pb-5`}>
          {imgData.map((val, i) => (
            <div
              key={i}
              className="xs:flex-[0_0_100%] sm:flex-[0_0_21%] md:flex-[0_0_30%] lg:[0_0_21%] xl:flex-[0_0_21%] 2xl:flex-[0_0_19%] p-6 pb-8 bg-white rounded-2xl cursor-pointer shadow-[1px_1px_10px_#606060]"
            >
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <motion.div className="card" variants={cardVariants}>
                  <div
                    style={{
                      background: `url('${val?.img}')`, backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "top",
                    }}
                    className="h-[230px] rounded-lg"
                  />
                  <p className="pt-3 pb-3 font-semibold last:text-black rounded-b-2xl text-center text-lg bg-white">
                    {val?.title}
                  </p>
                  <div className="w-28 mx-auto cursor-pointer text-center py-1.5 rounded-full text-white bg-[#ED0F5A] flex items-center justify-center gap-2"
                    onClick={() => {
                      handleFrame(val?.url);
                    }}
                  >
                    <p>Start</p>
                    <img src={arrow} alt="arrow" />
                  </div>
                </motion.div>
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feature;
