import { Contact } from "assets/media/contact";
import Mail from "assets/media/mail";
import React from "react";

const Header = () => {
    return (
        <div className="2xl:p-4">
            <div className="max-w-[1200px] xs:h-full sm:h-20 flex xs:flex-col sm:flex-row items-center m-auto xs:gap-6 sm:gap-0">
                <div className="flex-1 flex items-center justify-start text-center">
                    <img src="https://stg-assets.bldghealth.net/explainers/image/contractor_united.png" className="xs:w-full xs:h-[70px] xs:object-cover sm:w-[50%] sm:object-cover sm:h-[70px] bg-white" alt="logo" />
                </div>
                <div className="flex-1 xs:flex-col sm:flex-row flex justify-end items-center gap-3">
                    <div className="flex gap-8 xs:flex-col sm:flex-row items-center">
                    {/* <p className="text-white text-[23px] cursor-pointer">Product</p>
                        <p className="text-white text-[23px] cursor-pointer">Industries</p>
                        <p className="text-white text-[23px] cursor-pointer">Pricing</p>
                        <p className="text-white text-[23px] cursor-pointer">Blogs</p> */}
                        <div className="flex gap-2 items-center cursor-pointer">
                            <p className="mx-auto cursor-pointer text-center py-1.5 px-6 rounded-full text-white bg-[#ED0F5A] flex items-center justify-center gap-2"><a href="https://walkthru.ai/contact" target="_blank">Want Walkthru for your business?</a></p>
                        </div>
                        {/* <div className="flex gap-2 items-center cursor-pointer">
                            <p className="text-[#3a354199] text-white text-[23px]"><a href="https://app.walkthru.ai" target="_blank">Login</a></p>
                        </div> */}
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header