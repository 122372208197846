import React from "react";

const Chart = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" font-size="3.125rem" class="iconify iconify--mdi" width="1em" height="1em" viewBox="0 0 24 24">
            <path fill="#000" d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m0 14H5.2L4 17.2V4h16v12Z"></path>
        </svg>
    )
}

export default Chart